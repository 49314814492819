import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Settings } from '@common/core/config/settings.service';

@Component({
    selector: 'landing-coming-soon',
    templateUrl: './landing-coming-soon.component.html',
    styleUrls: [
        //
        '../dashboard/landing/landing.component.scss',
        '../dashboard/landing/assets/css/dist/block-library/style.min.css',
        '../dashboard/landing/assets/css/classic-themes.min.css',
        '../dashboard/landing/assets/themes/royal-elementor-kit/style.css',
        '../dashboard/landing/assets/plugins/elementor/assets/lib/eicons/css/elementor-icons.min.css',
        '../dashboard/landing/assets/plugins/elementor/assets/css/frontend-lite.min.css',
        '../dashboard/landing/assets/plugins/elementor/assets/lib/swiper/v8/css/swiper.min.css',
        '../dashboard/landing/assets/uploads/elementor/css/post-5.css',
        '../dashboard/landing/assets/uploads/elementor/css/global.css',
        '../dashboard/landing/assets/uploads/elementor/css/post-93.css',
        '../dashboard/landing/assets/plugins/elementor/assets/lib/font-awesome/css/fontawesome.min.css',
        '../dashboard/landing/assets/plugins/elementor/assets/lib/font-awesome/css/solid.min.css',
        '../dashboard/landing/assets/plugins/elementor/assets/lib/font-awesome/css/brands.min.css',
        '../dashboard/landing/assets/plugins/elementor/assets/lib/animations/animations.min.css',
        '../dashboard/landing/assets/plugins/elementor/assets/css/widget-icon-list.min.css',
        '../dashboard/landing/assets/plugins/elementor/assets/css/widget-icon-box.min.css',
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LandingComingSoonComponent implements OnInit {
    constructor(
        //
        public settings: Settings
    ) {}

    ngOnInit(): void {
        this.loadScripts();
    }
    private loadScripts() {
        const url = this.settings.get('base_url');
        const dynamicScripts = [
            //
            url + '/assets/homepage/plugins/elementor/assets/js/webpack.runtime.min.js',
            url + '/assets/homepage/js/jquery/jquery.min.js',
            url + '/assets/homepage/js/jquery/jquery-migrate.min.js',
            url + '/assets/homepage/plugins/elementor/assets/js/frontend-modules.min.js',
            url + '/assets/homepage/plugins/elementor/assets/lib/waypoints/waypoints.min.js',
            url + '/assets/homepage/js/jquery/ui/core.min.js',
            url + '/assets/homepage/custom.js',
            url + '/assets/homepage/plugins/elementor/assets/js/frontend.min.js',
        ];

        for (let i = 0; i < dynamicScripts.length; i++) {
            const node = document.createElement('script');
            node.src = dynamicScripts[i];
            node.type = 'text/javascript';
            node.async = false;
            document.getElementsByTagName('head')[0].appendChild(node);
        }
    }
}
