<material-navbar [hideRegisterButton]="state.mode === 'pricing'" [showLogo]="true"></material-navbar>

<div class="bg-wrapper">
    <mat-horizontal-stepper class="container main-content" [linear]="true">
        <ng-template matStepperIcon="edit">
            <mat-icon svgIcon="edit" class="size-16"></mat-icon>
        </ng-template>
        <mat-step [label]="'Subscription plan' | trans" [completed]="!!(state.initialPlan$ | async)">
            <select-plan-panel (selected)="nextStep()"></select-plan-panel>
            <contact-widget></contact-widget>
        </mat-step>

        <mat-step [label]="'Subscription duration' | trans" [completed]="!!(state.selectedPlan$ | async)">
            <select-plan-period-panel [showSidebar]="true" (selected)="nextStep()"></select-plan-period-panel>
        </mat-step>

        <mat-step [label]="'Payment Confirm' | trans" [completed]="false" *ngIf="state.mode === 'subscribe'">
            <div class="step-container payment-step">
                <div class="left-col">
                    <create-subscription-panel [plan]="state.selectedPlan$ | async" (loading)="loading$.next($event)" (completed)="onCompleted()"></create-subscription-panel>
                </div>

                <upgrade-page-aside [plan]="state.selectedPlan$ | async" class="right-col"></upgrade-page-aside>
            </div>
        </mat-step>
    </mat-horizontal-stepper>
</div>

<loading-indicator class="overlay" [isVisible]="loading$ | async"></loading-indicator>
