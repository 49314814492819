import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material.module';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';
import { NewProjectPageComponent } from './new-project-page/new-project-page.component';
import { TemplatesInfiniteScrollDirective } from './new-project-page/templates-infinite-scroll.directive';
import { MatTabsModule } from '@angular/material/tabs';
import { CustomDomainModule } from '@common/custom-domain/custom-domain.module';
import { LandingComponent } from './landing/landing.component';
import { SharedModule } from '../shared/shared.module';
import { MaterialNavbarModule } from '@common/core/ui/material-navbar/material-navbar.module';
import { AdHostModule } from '@common/core/ui/ad-host/ad-host.module';
import { TranslationsModule } from '@common/core/translations/translations.module';
import { FormatPipesModule } from '@common/core/ui/format-pipes/format-pipes.module';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NoResultsMessageModule } from '@common/core/ui/no-results-message/no-results-message.module';
import { LoadingIndicatorModule } from '@common/core/ui/loading-indicator/loading-indicator.module';
import { AppFooterModule } from '@common/shared/app-footer/app-footer.module';
import { ImageOrIconModule } from '@common/core/ui/image-or-icon/image-or-icon.module';
import { DomainComponent } from './domain/domain.component';
import { IntegrasiComponent } from './integrasi/integrasi.component';
import { MetaPixelIndexComponent } from './meta-pixel/meta-pixel-index/meta-pixel-index.component';
import { CrupdateModalMetaPixelComponent } from './meta-pixel/crupdate-modal-meta-pixel/crupdate-modal-meta-pixel.component';
import { MetaPixelEventIndexComponent } from './meta-pixel-event/meta-pixel-event-index/meta-pixel-event-index.component';
import { CrupdateModalMetaPixelEventComponent } from './meta-pixel-event/crupdate-modal-meta-pixel-event/crupdate-modal-meta-pixel-event.component';
import { MatSelectModule } from '@angular/material/select';
import { TelegramIndexComponent } from './telegram/telegram-index/telegram-index.component';
import { MatBadgeModule } from '@angular/material/badge';
import { ScrollIndexComponent } from './scroll/scroll-index/scroll-index.component';
import { CrupdateModalScrollComponent } from './scroll/crupdate-modal-scroll/crupdate-modal-scroll.component';
import { ShowProjectComponent } from './show-project/show-project.component';
import { FollowIndexComponent } from './follow/follow-index/follow-index.component';
import { CrupdateFollowComponent } from './follow/crupdate-follow/crupdate-follow.component';
import { ChooseFormModalComponent } from './choose-form-modal/choose-form-modal.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { BankIndexComponent } from './bank/bank-index/bank-index.component';
import { MatDatepickerModule } from '@angular/material/datepicker';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        DashboardRoutingModule,
        SharedModule,
        CustomDomainModule,
        MaterialNavbarModule,
        AdHostModule,
        TranslationsModule,
        FormatPipesModule,
        NoResultsMessageModule,
        LoadingIndicatorModule,
        AppFooterModule,
        ImageOrIconModule,
        DragDropModule,

        // material
        MaterialModule,
        MatTabsModule,
        MatIconModule,
        MatButtonModule,
        MatSelectModule,
        MatBadgeModule,
        NgApexchartsModule,
        MatDatepickerModule,
    ],
    declarations: [
        //
        DashboardComponent,
        NewProjectPageComponent,
        TemplatesInfiniteScrollDirective,
        LandingComponent,
        DomainComponent,
        IntegrasiComponent,
        MetaPixelIndexComponent,
        CrupdateModalMetaPixelComponent,
        MetaPixelEventIndexComponent,
        CrupdateModalMetaPixelEventComponent,
        TelegramIndexComponent,
        ScrollIndexComponent,
        CrupdateModalScrollComponent,
        ShowProjectComponent,
        FollowIndexComponent,
        CrupdateFollowComponent,
        ChooseFormModalComponent,
        BankIndexComponent,
    ],
})
export class DashboardModule {}
