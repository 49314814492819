import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Directive, ElementRef, OnInit } from '@angular/core';
import { Settings } from '@common/core/config/settings.service';
import { Translations } from '@common/core/translations/translations.service';
import { HomepageContent } from './homepage-content';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { SOLUTION } from './constant';

@Component({
    selector: 'landing',
    templateUrl: './landing.component.html',
    styleUrls: [
        //
        './landing.component.scss',
        // './assets/css/dist/block-library/style.min.css',
        './assets/css/classic-themes.min.css',
        './assets/themes/royal-elementor-kit/style.css',
        './assets/plugins/elementor/assets/lib/eicons/css/elementor-icons.min.css',
        './assets/plugins/elementor/assets/css/frontend-lite.min.css',
        './assets/plugins/elementor/assets/lib/swiper/v8/css/swiper.min.css',
        './assets/uploads/elementor/css/post-5.css',
        './assets/uploads/elementor/css/global.css',
        './assets/uploads/elementor/css/post-93.css',
        './assets/plugins/elementor/assets/lib/font-awesome/css/fontawesome.min.css',
        './assets/plugins/elementor/assets/lib/font-awesome/css/solid.min.css',
        './assets/plugins/elementor/assets/lib/font-awesome/css/brands.min.css',
        './assets/plugins/elementor/assets/lib/animations/animations.min.css',
        './assets/plugins/elementor/assets/css/widget-icon-list.min.css',
        './assets/plugins/elementor/assets/css/widget-icon-box.min.css',
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LandingComponent implements OnInit {
    @Directive({ selector: 'img' })
    public content: HomepageContent;
    public overlayBackground;
    public solutions = SOLUTION;

    constructor(
        //
        private el: ElementRef<HTMLImageElement>,
        public settings: Settings,
        private i18n: Translations,
        private cd: ChangeDetectorRef,
        private sanitizer: DomSanitizer
    ) {}

    ngOnInit() {
        const supports = 'loading' in HTMLImageElement.prototype;
        if (supports) {
            this.el.nativeElement.setAttribute('loading', 'lazy');
        } else {
            console.info('not support');
        }

        this.settings.all$().subscribe(() => {
            this.content = this.settings.getJson('homepage.appearance');
            this.overlayBackground = this.sanitizer.bypassSecurityTrustStyle(`linear-gradient(45deg, ${this.content.headerOverlayColor1} 0%, ${this.content.headerOverlayColor2} 100%)`);
            this.cd.markForCheck();
        });

        this.loadScripts();
    }

    public scrollToFeatures() {
        document.querySelector('.first-secondary-feature').scrollIntoView({ block: 'center', inline: 'center', behavior: 'smooth' });
    }

    public copyrightText() {
        const year = new Date().getFullYear();
        return this.i18n.t('Copyright © :year, All Rights Reserved', { year });
    }

    private loadScripts() {
        const url = this.settings.get('base_url');
        const dynamicScripts = [
            //
            url + '/assets/homepage/plugins/elementor/assets/js/webpack.runtime.min.js',
            url + '/assets/homepage/js/jquery/jquery.min.js',
            // url + '/assets/homepage/js/jquery/jquery-migrate.min.js',
            url + '/assets/homepage/plugins/elementor/assets/js/frontend-modules.min.js',
            url + '/assets/homepage/plugins/elementor/assets/lib/waypoints/waypoints.min.js',
            url + '/assets/homepage/js/jquery/ui/core.min.js',
            url + '/assets/homepage/custom.js',
            url + '/assets/homepage/plugins/elementor/assets/js/frontend.min.js',
        ];

        for (let i = 0; i < dynamicScripts.length; i++) {
            const node = document.createElement('script');
            node.src = dynamicScripts[i];
            node.type = 'text/javascript';
            node.async = false;
            document.getElementsByTagName('head')[0].appendChild(node);
        }
    }
}
