import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NOT_FOUND_ROUTES } from '@common/pages/not-found-routes';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('src/app/prospector/prospector.module').then((m) => m.ProspectorModule),
        // path: 'dashboard',
        // canActivate: [AuthGuard, CheckPermissionsGuard],
        // children: [
        //     {
        //         path: '',
        //         component: DashboardComponent,
        //         resolve: { projects: DashboardResolver },
        //         data: { permissions: ['projects.view'] },
        //     },
        //     { path: 'projects', redirectTo: '', pathMatch: 'full' },
        //     {
        //         path: 'projects/new',
        //         component: NewProjectPageComponent,
        //         resolve: { templates: TemplatesResolver },
        //         data: { permissions: ['projects.create', 'templates.view'] },
        //     },
        // ],
    },
    ...NOT_FOUND_ROUTES,
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule],
})
export class DashboardRoutingModule {}
