export const SOLUTION = [
    {
        value: 'Gak Pusing Ngurusin Hosting dan Server',
    },
    {
        value: 'Gak Perlu Harus Setup CPanel dll',
    },
    {
        value: 'Gak Perlu Setting WordPress dan Plugin',
    },
    {
        value: 'Gak Pusing Managemen Order banyak',
    },
    {
        value: 'Disediakan 100+ Free Template Landing Page',
    },
    {
        value: 'Dapat Mengukur Konversi Landing Page',
    },
];
