<!DOCTYPE html>
<html lang="id">
    <head>
        <meta charset="UTF-8" />
        <title>Prospector - Coming Soon</title>
        <meta name="robots" content="max-image-preview:large" />
        <link
            rel="stylesheet"
            id="google-fonts-1-css"
            href="https://fonts.googleapis.com/css?family='Poppins', sans-serif%3A100%2C100italic%2C200%2C200italic%2C300%2C300italic%2C400%2C400italic%2C500%2C500italic%2C600%2C600italic%2C700%2C700italic%2C800%2C800italic%2C900%2C900italic%7C'Poppins', sans-serif+Slab%3A100%2C100italic%2C200%2C200italic%2C300%2C300italic%2C400%2C400italic%2C500%2C500italic%2C600%2C600italic%2C700%2C700italic%2C800%2C800italic%2C900%2C900italic%7CSource+Sans+Pro%3A100%2C100italic%2C200%2C200italic%2C300%2C300italic%2C400%2C400italic%2C500%2C500italic%2C600%2C600italic%2C700%2C700italic%2C800%2C800italic%2C900%2C900italic&#038;display=swap&#038;ver=6.2"
            type="text/css"
            media="all"
        />
        <link rel="preconnect" href="https://fonts.gstatic.com/" crossorigin />
    </head>
    <body class="coming-soon home page-template page-template-elementor_canvas page page-id-93 wp-embed-responsive elementor-default elementor-template-canvas elementor-kit-5 elementor-page elementor-page-93">
        <div data-elementor-type="wp-page" data-elementor-id="93" class="coming-soon elementor elementor-93">
            <section data-section-id="section-1" class="coming-soon elementor-section elementor-top-section elementor-element elementor-element-5ccce7bf elementor-section-content-top elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-element_type="section" data-settings='{"background_background":"classic"}'>
                <div class="elementor-background-overlay"></div>
                <div class="elementor-container elementor-column-gap-wide">
                    <div class="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-423d8095" data-element_type="column">
                        <div class="elementor-widget-wrap elementor-element-populated">
                            <div class="elementor-element elementor-element-531f4f65 elementor-widget elementor-widget-image" data-element_type="widget" data-settings='{"_animation":"fadeInUp"}' data-widget_type="image.default">
                                <div class="elementor-widget-container">
                                    <style>
                                        /*! elementor - v3.11.5 - 14-03-2023 */
                                        .elementor-widget-image {
                                            text-align: center;
                                        }
                                        .elementor-widget-image a {
                                            display: inline-block;
                                        }
                                        .elementor-widget-image a img[src$='.svg'] {
                                            width: 48px;
                                        }
                                        .elementor-widget-image img {
                                            vertical-align: middle;
                                            display: inline-block;
                                        }
                                    </style>
                                    <img decoding="async" width="879" height="253" [src]="settings.get('branding.logo_light')" class="attachment-large size-large wp-image-66" alt="" />
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-661dfe69 animated-fast elementor-widget elementor-widget-heading" data-element_type="widget" data-settings='{"_animation":"fadeInUp"}' data-widget_type="heading.default">
                                <div class="elementor-widget-container">
                                    <style>
                                        /*! elementor - v3.11.5 - 14-03-2023 */
                                        .elementor-heading-title {
                                            padding: 0;
                                            margin: 0;
                                            line-height: 1;
                                        }
                                        .elementor-widget-heading .elementor-heading-title[class*='elementor-size-'] > a {
                                            color: inherit;
                                            font-size: inherit;
                                            line-height: inherit;
                                        }
                                        .elementor-widget-heading .elementor-heading-title.elementor-size-small {
                                            font-size: 15px;
                                        }
                                        .elementor-widget-heading .elementor-heading-title.elementor-size-medium {
                                            font-size: 19px;
                                        }
                                        .elementor-widget-heading .elementor-heading-title.elementor-size-large {
                                            font-size: 29px;
                                        }
                                        .elementor-widget-heading .elementor-heading-title.elementor-size-xl {
                                            font-size: 39px;
                                        }
                                        .elementor-widget-heading .elementor-heading-title.elementor-size-xxl {
                                            font-size: 59px;
                                        }
                                    </style>
                                    <h2 class="elementor-heading-title elementor-size-default header-title" trans>Landing Page No Ribet-Ribet Club</h2>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-691ae80c animated-fast elementor-widget elementor-widget-text-editor" data-element_type="widget" data-settings='{"_animation":"fadeInUp","_animation_delay":5}' data-widget_type="text-editor.default">
                                <div class="elementor-widget-container">
                                    <p class="header-description" trans>
                                        <!-- Descrition -->
                                        Lesatkan Bisnis Online Anda hanya dengan menggunakan PROSPECTOR.ID <br />
                                        Landing Page dan Form Builder dalam satu Plaftorm.
                                    </p>
                                </div>
                            </div>
                            <div class="elementor-element elementor-element-2079c47b elementor-mobile-align-center animated-fast elementor-align-center elementor-widget__width-auto elementor-widget elementor-widget-button" data-element_type="widget" data-settings='{"_animation":"fadeInUp","_animation_delay":10}' data-widget_type="button.default">
                                <div class="coming-soon-button" trans>Coming Soon</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </body>
</html>
